body {
    background-image: url('../../img/bg.jpg');
}

button i.fa {
    margin-right: 8px;
}

.form-group.required .control-label:after {
    content: " *";
    color: red;
}

.navbar-nav i.fa {
    margin-right: 8px;
}

table.table tbody tr td, table.table tbody tr th, table.table thead tr th {
	vertical-align: middle;
    font-size: 14px;
}

.panel-title {
    text-transform: uppercase;
    font-weight: 700;
}

.panel-title i.fa {
    margin-right: 6px;
}

a.list-group-item i.fa {
    margin-right: 12px;
}

.list-group-item-text i.fa {
    margin-right: 8px;
}

.panel-title>a {
    color: #666;
}

.tab-content {
    padding: 20px 20px 0 20px;
    border-bottom: 1px solid #DDD;
    border-left: 1px solid #DDD;
    border-right: 1px solid #DDD;
    border-radius: 0 0 5px 5px;
}

.btn {
    text-transform: uppercase;
    font-weight: 700;
}
